import * as React from 'react';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

import '../styles/shareOptions.less';

interface ShareOptions {
    title: string;
    description: string;
}

interface SiteMetadata {
    site: {
        siteMetadata: {
            siteUrl: string;
        };
    };
}

type ShareType = 'facebook' | 'twitter' | 'linkedin';

const query = graphql`
    {
        site {
            siteMetadata {
                siteUrl: siteUrl
            }
        }
    }
`;

const ShareOptions = ({ title, description }: ShareOptions): JSX.Element => {
    const { pathname }: any = useLocation();
    const { site }: SiteMetadata = useStaticQuery(query);
    const pageURL = `${site.siteMetadata.siteUrl}${pathname}`;

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        const type = target.dataset.type as ShareType;
        const defaultWindowSettings = 'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,';

        let windowHeight: number;
        let windowWidth: number;
        let url: string;

        switch (type) {
            case 'facebook':
                url = `https://www.facebook.com/sharer.php?u=${pageURL}&t=${title}`;
                windowHeight = 285;
                windowWidth = 550;
                window.open(url, type, `${defaultWindowSettings}width=${windowWidth},height=${windowHeight},resizable=1`);
                break;
            case 'twitter':
                url = `https://twitter.com/share?url=${pageURL}&amp;text=${description}&amp;hashtags=ccmedia`;
                windowHeight = 400;
                windowWidth = 600;
                window.open(url, type, `${defaultWindowSettings}width=${windowWidth},height=${windowHeight},resizable=1`);
                break;
            case 'linkedin':
                url = `https://www.linkedin.com/shareArticle?mini=true&url=${pageURL}`;
                windowHeight = 400;
                windowWidth = 600;
                window.open(url, type, `${defaultWindowSettings}width=${windowWidth},height=${windowHeight},resizable=1`);
                break;
            default:
                break;
        }
    };

    return (
        <div className="share">
            <div className="share__item share__item--facebook" data-type="facebook" onClick={handleClick} />
            <div className="share__item share__item--twitter" data-type="twitter" onClick={handleClick} />
            <div className="share__item share__item--linkedin" data-type="linkedin" onClick={handleClick} />
        </div>
    );
};

export default ShareOptions;
