import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import ShareOptions from '../components/ShareOptions';
import Layout from '../components/Layout';
import CTA from '../components/CTA';

import '../styles/posts.less';

interface Markdown {
    data: MarkdownData;
}

interface MarkdownData {
    markdownRemark: MarkdownRemark;
}

interface MarkdownRemark {
    html: string;
    frontmatter: {
        date: Date;
        slug: string;
        title: string;
        excerpt: string;
        source: string;
        sourceUrl: string;
        featuredImage: {
            childImageSharp: {
                gatsbyImageData: IGatsbyImageData;
            };
        };
    };
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                excerpt
                source
                sourceUrl
                featuredImage {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
            }
        }
    }
`;

const NewsPost = ({ data }: Markdown): JSX.Element => {
    const { markdownRemark }: MarkdownData = data;
    const { frontmatter, html }: MarkdownRemark = markdownRemark;
    const source: string | JSX.Element = frontmatter.sourceUrl ? (
        <a href={frontmatter.sourceUrl} rel="external nofollow noopener" target="_blank">
            {frontmatter.source}
        </a>
    ) : (
        frontmatter.source
    );

    return (
        <>
            <Helmet title={`${frontmatter.title} - News`} />
            <Layout>
                <div className="page page__lg">
                    <div className="hero">
                        <GatsbyImage image={frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt={frontmatter.title} />
                        <div className="hero__wrp">
                            <h1 className="hero__title">{frontmatter.title}</h1>
                        </div>
                    </div>
                    <div className="page__cnt">
                        <div className="news__post">
                            <div className="post__social">
                                <ShareOptions title={frontmatter.title} description={frontmatter.excerpt} />
                            </div>
                            <div className="post">
                                <p className="post__excerpt">{frontmatter.excerpt}</p>
                                <div className="post__cnt" dangerouslySetInnerHTML={{ __html: html }} />
                                <p className="post__source">Source: Original article {source}</p>
                            </div>
                        </div>
                        <div className="news__back">
                            <CTA classname="details" path="/news/" text="Back to News" />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default NewsPost;
